body {
  font-size: 0.875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
   * Sidebar
   */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  max-height: 100px;
}

@media (min-width: 768px) {
  .sidebar {
    max-height: 999px;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  }
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  height: 48px;
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@media (min-width: 768px) {
  .sidebar-sticky {
    height: calc(100vh - 48px);
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  cursor: pointer;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
   * Navbar
   */

.sidebar .navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  /* background-color: rgba(0, 0, 0, 0.25); */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

/*
   * Utilities
   */

.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/* Make datepicker input same as bootstrap */

.datePicker .react-datepicker-wrapper {
  width: fit-content;
}

.datePicker .react-datepicker-wrapper input {
  width: 120px;
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.datePicker .react-datepicker-wrapper input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.datePicker .react-datepicker-wrapper input:focus-within {
  outline: none;
}

.datePickerLong {
  width: fit-content;
}

.datePickerLong .react-datepicker-wrapper input {
  width: 220px;
}

#copyBtn {
  cursor: pointer;
  transition: color 0.05s ease-in-out;
}

#copyBtn:hover {
  color: #17a2b8;
}

#flexRadioDefault1,
#flexRadioDefault2 {
  position: relative;
  bottom: 2px;
  min-height: 15.99px;
  margin-right: 7px;
  min-width: 15.99px;
}

#hoverDel:hover {
  background-color: #dc3545 !important;
  /* color: #000 !important; */
}

.bg-custom-highlight {
  background-color: #3dcc4a !important;
}

/*full height minus header */
.fullHeight {
  min-height: calc(100vh - 48px);
}

#priceTable td div,
#priceTable th div {
  display: flex;
  align-items: center;
  height: 48.99px;
}
#priceTable td div {
  justify-content: center;
}

#priceTable td,
#priceTable th {
  padding-left: 4px;
  padding-right: 4px;
}

/* copied to clipboard animation */

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
