@import "./raleway.css";

body {
  font-family: "raleway", sans-serif !important;
}

// because raleway-font numbers don't look professional
.numFont,
textarea,
select,
input {
  font-family: sans-serif !important;
}

// buttons + links
$primary: #1cc995;
.btn:not(.btn-outline-secondary):not(#copyBtn) {
  color: #fff !important;
  font-weight: 500 !important;
}
a,
.btn {
  transition: transform 0.1s ease-in-out;
}

a:not(.btn):hover {
  // text-decoration: underline;
  color: $primary !important;
}

.btn:hover {
  transform: scale(0.99);
}

a:active,
.btn:active {
  transform: scale(0.95);
}

@media (min-width: 576px) {
  /* needed for checkout window */
  .rounded-md {
    border-radius: 0.375rem !important;
  }

  /* fix for stripe payment element wrapper */
  .stripeFormFix {
    min-width: 479.99px;
  }
}

// used for products flex
@media (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
}

@media (max-width: 576px) {
  // for success page
  .maxHeightOnPhone {
    height: 100vh;
  }
  .maxWidthOnPhone {
    width: 100%;
  }

  // to keep some buttons short always
  .btn:not(.keepBtnShortOnPhone) {
    width: 100%;
  }
}

@import "~bootstrap/scss/bootstrap.scss";
